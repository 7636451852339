.financiar{
    width: 100%;
    height: fit-content;
    background-color:azure;
    align-items:center;
    text-align:justify;
    justify-content:start;
    vertical-align:top;
    flex-direction: column;
    display: flex;
}

.introducaoFin{
    width: 85%;
    height: fit-content;
    margin-top: 30px;
    margin-bottom: 50px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    align-items:start;
    text-align:justify;
    justify-content:start;
    vertical-align:top;
    flex-direction: column;
    display: flex;
}

.introducaoFin li{
    padding-block:8px;
}

.introducaoFin ul{
    list-style-position: inside;
}

.introducaoFin ol{
    list-style-position: inside;
}

p{
    padding-block:5px;
    align-items:start;
    text-align:justify;
    justify-content:start;
    vertical-align:top;
    flex-direction: row;
    display: flex;
}

.introducaoFin a{
    padding-block:5px;
    align-items:start;
    text-align:justify;
    justify-content:start;
    vertical-align:top;
    flex-direction: row;
    display: flex;
    color: black;
}

.introducaoFin h1{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    
    padding-top:10px;
    color: black;
}

h2{
    padding-block:10px;
}

h3{
    padding-block:10px;
}