.MainFin{
    width: 100%;
    height: 250px;
}

option{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    
}

.introducao h1{
    color: black;
}

.heroFin{
    width: 100%;
    height: 250px;
    background-position: bottom -50px right 0px;
    background-size: cover;
    align-items: center;
    text-align: center;
    justify-content:center;
    vertical-align:middle;
    flex-direction: column;
    display: flex;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
    color: black;
}

.heroFin h2{
    align-items: center;
    text-align: center;
    justify-content:center;
    vertical-align:middle;
    flex-direction: column;
    display: flex;
}

@media (max-width:481px)  { 
    .heroFin{
        background-position: bottom -8px right 0px;
        background-size:cover;
        height: 170px;
    }
    .MainFin{
        height: 170px;
    }
}