@media (max-width:320px)  { 
    h1 {
        font-size: 13px;
    }
    a, p, div, option{
        font-size: 12px;
    }
}

@media (min-width:320px)  { 
    h1 {
        font-size: 13px;
    }
    a, p, div, option {
        font-size: 12px;
    }
}

@media (min-width:481px)  { 
    h1 {
        font-size: 13px;
    }
    a, p, div, option {
        font-size: 12px;
    }
}

@media (min-width:641px)  { 
    h1 {
        font-size: 13px;
    }
    a, p, div, option {
        font-size: 12px;
    }
}

@media (min-width:961px)  { 
    h1 {
        font-size: 1.8vw;
    }
    a, p, div, option {
        font-size: 1.3vw;
    }
}

@media (min-width:1025px) { 
    h1 {
        font-size: 1.8vw;
    }
    a, p, div, option{
        font-size: 1.3vw;
    }
}