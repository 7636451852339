.MainDocs{
    width: 100%;
    height: 100%;
}

option{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    
}

.heroDocs{
    width: 100%;
    height: 250px;
    background-position: bottom -50px right 0px;
    background-size:cover;
    align-items: center;
    text-align: center;
    justify-content:center;
    vertical-align:middle;
    flex-direction: column;
    display: flex;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
    color: black;
}

@media (max-width:481px)  { 
    .heroDocs{
        background-position: bottom -8px right 0px;
        background-size:cover;
        height: 170px;
    }
    .MainDocs{
        height: 170px;
    }
}

.heroDocs h2{
    align-items: center;
    text-align: center;
    justify-content:center;
    vertical-align:middle;
    flex-direction: column;
    display: flex;
}