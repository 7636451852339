.MainSomos{
    width: 100%;
    height: 250px;
}
.heroSomos{
    width: 100%;
    height: 250px;
    background-position: bottom -100px right 0px;
    background-size: cover;
    align-items: center;
    text-align: center;
    justify-content:center;
    vertical-align:middle;
    flex-direction: column;
    display: flex;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
    color: white;
}

.heroSomos h2{
    align-items: center;
    text-align: center;
    justify-content:center;
    vertical-align:middle;
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
    color: rgb(255, 255, 255);
}

@media (max-width:481px)  { 
    .heroSomos{
        background-position: bottom -8px right 0px;
        background-size:cover;
        height: 170px;
    }
    .MainSomos{
        height: 170px;
    }
}