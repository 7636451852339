@import "~react-image-gallery/styles/css/image-gallery.css";

.photoProperty {
    margin: 0 auto;
    width: 100%;
}

.ltBoxDataN{
    width: 250px;
    height: 30px;
    
    flex-direction: row;
    display: flex;
    vertical-align:middle;
    align-items: center;
    text-align: center;
}

.iconX{
    width: 19px;
    height: 20px;
    margin-right: 10px;
}

.boxTituloNovo{
    width: 90%;
    height: 10%;
    padding: 10px;
    padding-left: 30px;
    
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
    color: rgb(88, 90, 86);
    align-items:start;
    text-align:justify;
    justify-content:left;
    vertical-align:top;
    display: flex;
}

.image-gallery button:hover {
    background-color: transparent !important;
    border: 2px black;
}

.image-gallery-swipe .image-gallery-slides  {
    width: 40%;
    margin: auto;
}

@media (max-width: 650px)  { 
    .image-gallery-swipe .image-gallery-slides  {
        width: 80%;
        margin: auto;
    }
}


.fullscreen .image-gallery-swipe .image-gallery-slides {
    width: 100%;
    margin: initial;
}

.image-gallery-right-nav {
    right: 50px !important
}

@media (max-width: 650px)  { 
    .image-gallery-right-nav {
        right: 35px !important
    }
}

.image-gallery-icon.image-gallery-play-button {
    bottom: 10px !important;
}

.image-gallery-fullscreen-button {
    right: 15px !important;
    bottom: 16px !important;
}

.boxDataImovelPropertyDetails {
    width: 100% !important;
}

.boxAmenitiesData.boxAmenitiesDataPropertyDetails {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100% !important;
    flex-grow: 1;
    gap: 30px;
    margin-bottom: 20px;
}

.singlePropertyDetail .address {
    
    color: rgb(88, 90, 86);
    padding-left: 20px;
    margin-bottom: 60px;
    font-size: 18px;
    align-items:start;
    text-align:justify;
    justify-content:left;
    vertical-align:top;
    display: flex;
}

.boxAmenitiesDataPropertyDetails .ltBoxData {
    /* width: 32%; */
    width: auto;
}

