/* .logo{
    background-color: rgb(29,70,67);
    width: 170px;
    height: 70px;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
} */

main {
    width: 100%;
    height: fit-content;
}

.navHead a{
    color: black;
	text-decoration: none;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
    color: white;
    
}

.hero{
    width: 100%;
    height: 250px;
    background-position: bottom -600px right 0px;
    background-size: cover;
    align-items: center;
    text-align: center;
    justify-content:center;
    vertical-align:middle;
    flex-direction: column;
    display: flex;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
    color: white;
}

.hero h2{
    align-items: center;
    text-align: center;
    justify-content:center;
    vertical-align:middle;
    flex-direction: column;
    display: flex;
    color: rgb(255, 255, 255);
}

.boxFilter{
    width: 350px;
    height: fit-content;
    background-color: rgb(29,70,67);
    border-radius: 5px;
    align-items: center;
    text-align: center;
    justify-content:center;
    vertical-align:middle;
    display: flex;
    flex-wrap: wrap;
}

.divBt{
    align-items: center;
    text-align: center;
    justify-content:center;
    vertical-align:middle;
    display: flex;
}

.MainBox{
    width: 100%;
    height: 500px;
    align-items:start;
    text-align: center;
    justify-content:space-around;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
}

.FormSearchMain2{
    background-color:white;
    width: 100%;
    height: 600px;
    align-items:start;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
}

.sltMode{
    width: 300px;
    height: 35px;
    padding: 5px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    display: flex;
    flex-wrap: wrap;
}

.mSltFilter{
    width: 100%;
    height: 70px;
    margin-top: 5px;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    flex-direction: column;
    display: flex;
}

label{
    width: 300px;
    height: 30px;
    align-items:start;
    text-align:left;
    justify-content:left;
    vertical-align:middle;
    flex-direction: column;
    display: flex;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #f4f5f2;
}

.lbValor{
    width: 124px;
    height: 30px;
    align-items:center;
    text-align:justify;
    justify-content:center;
    vertical-align:middle;
    flex-direction: column;
    display: flex;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #f4f5f2;
}

.mSltPrice{
    width: 100%;
    height: 70px;
    margin-top: 10px;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
}

.inputValor{
    width: 120px;
    height: 35px;
    
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.btSearchAvance{
    width: 100px;
    height: 40px;
    background-color: rgb(88, 90, 86);
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
    color: white;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
    cursor: pointer;
}

.btBuscarIm{
    width: 80px;
    height: 30px;
    margin-block: 20px;
    background-color: rgb(88, 90, 86);
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
    
    color: white;
    align-items:center;
    text-align:center;
    justify-content:center;
    vertical-align:middle;
    flex-direction:row;
    display: flex;
    border: 0.5px solid white;
    cursor: pointer;
}

.btSearchAd{
    width: 100%;
    height: 50px;
    margin-top: 50px;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
}

.ipPrice{
    width: 180px;
    height: 80px;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    flex-direction:column;
    display: flex;
}

h1{
    margin:10px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
    color: rgb(88, 90, 86);
    margin-bottom: 10px;
    color: white;
}

.qtdEncontrado{
    background-color: rgb(29,70,67);
    height: 60px;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content:left;
    vertical-align:middle;
    display: flex;
}

.qtdImoveis{
    
    margin:10px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
    font-size: 23px;
    color: white;
    margin-bottom: 10px;
    flex-direction: row;
    display: flex;
}

.qtdEncontrado h1{
    font-size: 23px;
}

.carrosel{
    width: 800px;
    height: fit-content;
    overflow-y: auto;
    scroll-behavior: smooth;
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
}

.BoxImovel{
    width: 100%;
    height: 240px;
    margin-bottom: 10px;
    align-items:start;
    text-align: center;
    justify-content:left;
    vertical-align:top;
    flex-direction: row;
    background-color:whitesmoke;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
}

.boxPicture{
    width: 35%;
    height: 100%;
}

.boxDataImovel{
    width: 60%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
}

.boxTitulo{
    width: 90%;
    height: 10%;
    padding: 10px;
    padding-left: 30px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
    color: rgb(88, 90, 86);
    align-items:start;
    text-align:justify;
    justify-content:left;
    vertical-align:top;
    display: flex;
    flex-wrap: wrap;
}

.boxAmenitiesData{
    width: 90%;
    height: 70%;
    padding: 8px;
    padding-left: 30px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:normal;
    color: rgb(88, 90, 86);
    align-items:start;
    text-align:justify;
    justify-content:left;
    flex-direction: column;
    vertical-align:top;
    display: flex;
}

.iconS{
    width: 14px;
    height: 15px;
    margin-right: 10px;
}

.ltBoxData{
    width: 95%;
    height: 30px;
    flex-direction: row;
    display: flex;
    vertical-align:middle;
    align-items: center;
    text-align: center;
}

select{
    height: 40px;
}

.linha1select{
    flex-direction: row;
    display: flex;
    justify-content: space-around;
    width: 900px;
    height: 75px;
    align-items: center;
    text-align: center;
    vertical-align:middle;
}

.linha2select{
    flex-direction: row;
    display: flex;
    justify-content: space-around;
    width: 900px;
    height: 75px;
    align-items: center;
    text-align: center;
    vertical-align:middle;
}

.botaoBuscar{
    width: 100px;
    height: 39px;
    background-color: rgb(29,70,67);
    align-items: center;
    text-align: center;
    justify-content:center;
    vertical-align:middle;
    display: flex;
    text-decoration:none;
    border: none;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
    color: white;
    cursor: pointer;
}

.listaImoveis{
    background-color:white;
    margin-top: 20px;
    width: 100%;
    height: 500px;
    align-items:start;
    text-align: center;
    justify-content: center;
    vertical-align:top;
    flex-direction: row;
    display: flex;
}

.tituloBoxVenda{
    width: 100%;
    height: 80px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
    align-items:center;
    text-align: center;
    justify-content:center;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
}

.imFotoMain{
    width: 100%;
    height: 65%;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    flex-direction: column;
    display: flex;
}

.boxPreco{
    color: white;
    align-items: center;
    text-align: center;
    justify-content: center;
    justify-content:space-around;
    flex-direction: row;
    display: flex;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #e48134;
}

.tipoContrato{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
}

.precoImovel{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
}

.imFoto{
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 5px;
}

.imBairroCidade{
    width: 100%;
    height: 10%;
    color: white;
    border-top: 0.5px solid #e48134;
    background-color: #e48134;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
}

.imId{
    width: 100%;
    height: 10%;
    border-top: 0.5px solid #e48134;
    background-color: #e48134;
    color: white;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
}

.imAmenities{
    width: 100%;
    height: 15%;
    color: white;
    border-top: 0.5px solid #e48134;
    background-color: #e48134;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.iconsAmenities{
    width: 100%;
    height: 52px;
    background-color: #e48134;
    align-items: center;
    text-align: center;
    justify-content:space-around;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
}

.icon{
    width: 25px;
    height: 25px;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    display: flex;
}

.iconSeta{
    padding: 10px;
    margin: 3px;
    background-color: rgb(93, 129, 126);
    cursor: pointer;
    width: 20px;
    height: 20px;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    display: flex;
}

/* .iconSetaPai{
    width: 95%;
    height: 50px;
    align-items: center;
    text-align: center;
    justify-content:right;
    vertical-align:middle;
    display: flex;
} */

/* .boxContato{
    background-color: rgb(29,70,67);
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
    align-items: center;
    text-align: center;
    justify-content:space-around;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
} */

/* .sloganMedio{
    width: 400px;
    height: 65px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
    color: white;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
} */

/* .botaoContato{
    width: 200px;
    height: 30px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
    padding: 10px;
    padding-bottom: 15px;
    color: white;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
    border: 0.5px solid white;
    cursor: pointer;
} */

.quickInfos{
    width: 100%;
    height: 50px;
    background-color: #f4f5f2;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    
    align-items: center;
    text-align: center;
    justify-content:space-around;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
}

.qkIcon{
    width: 15px;
    height: 15px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    display: flex;
}

.qkData{
    width: 15px;
    height: 15px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    display: flex;
}

.boxConta{
    width: 380px;
    height: 350px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    align-items:start;
    text-align: center;
    justify-content:start;
    vertical-align:middle;
    flex-direction: column;
    display: flex;
}

.boxMapa{
    width: 320px;
    height: 350px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    align-items:start;
    text-align: center;
    justify-content:start;
    vertical-align:middle;
    flex-direction: column;
    display: flex;
}

.boxConta p{
    width: 100%;
    height: 30px;
    
    margin-top: 10px;
    margin-bottom: 10px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: white;
    align-items:center;
    text-align:left;
    justify-content:left;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
}

.boxMapa p{
    width: 100%;
    height: 30px;
    
    margin-top: 10px;
    margin-bottom: 10px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: white;
    align-items:center;
    text-align:left;
    justify-content:left;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
}

.boxFootTitulo{
    font-weight: bold;
}

.textDif{
    width: 300px;
    height: 40px;
    
    margin-top: 10px;
    margin-bottom: 10px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: white;
    align-items:center;
    text-align:justify;
    justify-content:left;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
}

.inputEmail{
    width: 300px;
    height: 30px;
}

.boxBotao{
    width: 100%;
    height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    align-items:center;
    text-align:left;
    justify-content:left;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
}

.boxEmailMsg{
    width: 100%;
    height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    align-items:center;
    text-align:left;
    justify-content:left;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
}

.btEnviar{
    width: 100px;
    height: 30px;
    background-color: rgb(88, 90, 86);
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
    
    color: white;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
    cursor: pointer;
}

.boxFootLinha{
    flex-direction: row;
}

.footExtra{
    width: 100%;
    height: 50px;
    background-color: #f4f5f2;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    align-items: center;
    text-align: center;
    justify-content:space-evenly;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
}

@media (max-width:481px)  { 
    .carrosel{
        width: 350px;
        height: fit-content;
        overflow-y: auto;
        scroll-behavior: smooth;
        flex-direction: column;
        display: flex;
        flex-wrap: wrap;
    }

    .FormSearchMain2{
        width: 100%;
    }

    main{
        width: 100%;
    }

    body {
        width: 100%;
    }

    .MainBox{
        width: 100%;
        border-radius:0%;
        height: 550px;
    }
    .FormSearchMain2{
        width: 100%;
        border-radius:0%;
        height: 550px;
    }
    .boxFilter{
        width: 100%;
        height: 500px;
        border-radius:0px;
        text-align:center;
        justify-content:center;
        flex-direction: column;
        display: flex;
        flex-wrap: wrap;
    }
    .BoxImovel{
        width: 350px;
        height: fit-content;
        margin-bottom: 10px;
        align-items:center;
        text-align: center;
        justify-content:center;
        vertical-align:top;
        flex-direction: column;
        background-color:whitesmoke;
        border-radius: 5px;
        display: flex;
        flex-wrap: wrap;
    }
    .boxAmenitiesData{
        width: 350px;
        font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight:normal;
        color: rgb(88, 90, 86);
        align-items:start;
        text-align:justify;
        justify-content:left;
        flex-direction: column;
        vertical-align:top;
        display: flex;
    }
    .boxPicture{
        padding-top: 20px;
        width: 350px;
    }
    .imFoto{
        width: 200px;
        height: 200px;
    }
    .boxDataImovel{
        width: 350px;
        display: flex;
        flex-wrap: wrap;
    }
    .boxTitulo{
        text-align:center;
        justify-content:center;
        font-size: 13px;
    }

    .mSltFilter{
        width: 300px;
        height: fit-content;
    }
    label{
        width: 200px;
        height: fit-content;
        text-align:center;
        justify-content:center;
    }
    .sltMode{
        width: 200px;
    }
    .hero{
        background-position: bottom -100px right 0px;
        background-size:cover;
        height: 170px;
    }
    .qtdEncontrado{
        background-color: rgb(29,70,67);
        height: 60px;
        align-items:center;
        text-align: center;
        justify-content:center;
        vertical-align:middle;
        display: flex;
    }
    .qtdImoveis{
        font-size: 15px;
    }
    .qtdEncontrado h1{
        font-size: 15px;
    }
}