.item {
    width: 500px;
    padding: 1em;
    margin: 1em;
    border: 1px solid black;
    display: flex;
    justify-content: space-around;
  }

  button:hover{
    /* background-color:#e48134; */
    color:black;
    cursor: pointer;
    font-weight: bolder;
  }

  .currentPage{
    background-color: #e48134;
    color:black;
  }

  .numPagesNav{
    width: 250px;
    height: 30px;
    padding: 10px;
    align-items:center;
    text-align: center;
    justify-content:center;
    vertical-align:middle;
    display: flex;
  }

  .numPagesNav button{
    padding-block: 5px;
    padding-inline: 10px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:normal;
  }

  .Pagination{
    display: flex;
    justify-content: center;
    padding: 5px;
  }