.highlts{
    flex-wrap: wrap;
}

.listaImoveis{
    background-color:white;
    margin-top: 20px;
    width: 100%;
    height: 500px;
    align-items:start;
    text-align: center;
    justify-content: center;
    vertical-align:top;
    flex-direction: row;
    display: flex;
}

@media (max-width:481px)  { 
    .boxImovel{
        margin-bottom: 30px;
    }
}

.opcaoImoveis{
    background-color:white;
    margin-bottom: 20px;
    align-items: center;
    text-align: center;
    justify-content:space-around;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
}

.tituloBoxVenda{
    width: 100%;
    height: 60px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
    align-items:end;
    text-align: center;
    justify-content:center;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
}

.tituloBoxVenda h1{
    color: black;
}

.boxImovel{
    background-color:white;
    width: 330px;
    height: 100%;
    align-items:initial;
    text-align: center;
    justify-content:initial;
    vertical-align:top;
    flex-direction: column;
    display: flex;
    box-shadow: 3px 3px 10px rgb(121, 138, 127);
    border-radius: 10px;
}

.imFotoMainV{
    width: 100%;
    height: 65%;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    flex-direction: column;
    display: flex;
}

.imFotoV:hover{
    opacity: 0.7;
}

.boxPreco{
    width: 100%;
    color: white;
    padding-block: 10px;
    align-items: center;
    text-align: center;
    justify-content: center;
    justify-content:space-around;
    flex-direction: row;
    display: flex;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #e48134;
}

.tipoContrato{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
}

.precoImovel{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
}

.imFotoV{
    width: 100%;
    height: 250px;
    cursor: pointer;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.linkImage{
    width: 100%;
    height: 250px;
    cursor: pointer;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.imBairroCidade{
    width: 100%;
    height: 10%;
    color: white;
    padding-block: 10px;
    border-top: 0.5px solid #e48134;
    background-color: #e48134;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
}

.imId{
    width: 100%;
    height: 10%;
    padding-block: 10px;
    border-top: 0.5px solid #e48134;
    background-color: #e48134;
    color: white;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
}

.imAmenities{
    width: 100%;
    height: 15%;
    color: white;
    border-top: 0.5px solid #e48134;
    background-color: #e48134;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.iconsAmenities{
    width: 100%;
    height: 52px;
    padding-block: 10px;
    background-color: #e48134;
    align-items: center;
    text-align: center;
    justify-content:space-around;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
}

.iconsAmenities p{
    color:white;

}

.icon{
    width: 25px;
    height: 25px;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    display: flex;
}

.iconSeta{
    padding: 10px;
    margin: 3px;
    background-color: rgb(93, 129, 126);
    cursor: pointer;
    width: 20px;
    height: 20px;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    display: flex;
}

.iconSetaPai{
    width: 95%;
    height: 25px;
    align-items: center;
    text-align: center;
    justify-content:right;
    vertical-align:middle;
    display: flex;
}

/* .boxContato{
    background-color: rgb(29,70,67);
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
    align-items: center;
    text-align: center;
    justify-content:space-around;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
} */