.aEmpresa{
    width: 100%;
    height: fit-content;
    background-color:azure;
    align-items:center;
    text-align:justify;
    justify-content:start;
    vertical-align:top;
    flex-direction: column;
    display: flex;
}

.curriculo{
    width: 100%;
    height: fit-content;
    flex-direction: row;
    display: flex;
    justify-content:space-between;
    padding: 10px;
    padding-left: 0px;
}

.curriculoTexto1{
    margin-right: 30px;
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items:center;
    text-align:left;
    justify-content:center;
    vertical-align:middle;
    flex-direction: column;
}

.curriculoTexto2{
    margin-left: 30px;
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items:center;
    text-align:left;
    justify-content:center;
    vertical-align:middle;
    flex-direction: column;
}

.imgJuliano{
    width: 220px;
    border-radius: 5px;
    display: flex;
    align-items:center;
    text-align:center;
    justify-content:center;
    vertical-align:middle;
}

.imgRodolpho{
    width: 180px;
    border-radius: 5px;
}

.valores{
    width: 85%;
    height: fit-content;
    margin-top: 30px;
    margin-bottom: 50px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    align-items:start;
    text-align:justify;
    justify-content:start;
    vertical-align:top;
    flex-direction: column;
    display: flex;
}

.nossaEquipe{
    width: 100%;
    height: fit-content;
    margin-top: 30px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    align-items:start;
    text-align:justify;
    justify-content:start;
    vertical-align:top;
    flex-direction: column;
    display: flex;
}

p{
    padding-block:10px;
    color: black;
    align-items:center;
    text-align:justify;
    justify-content:center;
    vertical-align:middle;
    display: flex;
}

.valores li{
    padding-block:8px;
}

.valores ul{
    list-style-position: inside;
}

.valores p{
    padding-block:5px;
}

.valores h1{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding-top:10px;
    color: black;
    margin: 0px;
    
}

h2{
    padding-block:10px;
    color: black;
    align-items:start;
    text-align:left;
    justify-content:left;
    vertical-align:middle;
    display: flex;
    width: 100%;
    height: fit-content;
}

h3{
    padding-block:10px;
    color: black;
}

@media (max-width:481px)  { 
    .curriculo{
        flex-wrap: wrap;
        flex-direction:column-reverse;
        align-items:center;
        text-align:center;
        justify-content:center;
        vertical-align:middle;
    }
    .curriculoTexto1{
        margin-right: 0px;
        text-align:center;
    }
    .curriculoTexto2{
        margin-left: 0px;
        text-align:center;
    }
}