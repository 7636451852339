.Main{
    width: 100%;
    height: 100%;
    background-color:darkgray;
}

option{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    
}

.heroLanding{
    width: 100%;
    height: 250px;
    background-position: bottom -100px right 30px;
    background-size: cover;
    align-items: center;
    text-align: center;
    justify-content:center;
    vertical-align:middle;
    flex-direction: column;
    display: flex;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
    color: white;
}

.botaoHero .saibaMais{
    padding: 10px 30px;
    background-color: rgb(29,70,67);
    align-items: center;
    text-align: center;
    justify-content:center;
    vertical-align:middle;
    display: flex;
    text-decoration:none;
    border: none;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
    color: white;
    cursor: pointer;
}

.botaoHero{
    margin-top: 10px;
    background-color: rgb(29,70,67);
    align-items: center;
    text-align: center;
    justify-content:center;
    vertical-align:middle;
    display: flex;
    text-decoration:none;
    border: none;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
    color: white;
    cursor: pointer;
}

.FormSearchLanding{
    padding: 20px;
    background-color:darkgray;
    align-items: center;
    text-align: center;
    justify-content:center;
    vertical-align:middle;
    align-content: space-around;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
}

.barraSelect{
    width: 200px;
}

@media (min-width:1000px)  { 
    .barraSelect{
        width: 330px;
    }
}

.sltModeLandContainer {
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items:center;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.sltModeLandContainer > label {
    color: black;
    width: 200px;
}

.barraSelect > label {
    color: black;
}

.sltModeLand{
    width: 200px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.inputValorLand{
    width: 145px;
    height: 38px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.botaoBuscarLand{
    align-items: center;
    text-align: center;
    justify-content:center;
    vertical-align:middle;
    margin: auto;
    display: flex;
    text-decoration:none;
    border: none;
    cursor: pointer;
}

.botaoBuscarLand p{
    padding: 10px 30px;
    background-color: rgb(29,70,67);
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
    color: white;
}

.botaoBuscarLand :hover{
    background-color: rgb(26, 95, 91);
    color: white;
}

.saibaMais{
    text-decoration: none;
    color: white;
}

.saibaMais:hover{
    background-color: rgb(26, 95, 91);
    color: white;
}

.botaoHeroLand button:hover{
    background-color: rgb(26, 95, 91);
    color: white;
}

@media (max-width:481px)  { 
    .heroLanding{
        background-position: bottom 2px right -50px;
        background-size:cover;
        height: 170px;
    }
}