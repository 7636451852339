.boxFooter{
    background-color: rgb(29,70,67);
    width: 100%;
    height: fit-content;
    align-items: center;
    text-align: center;
    justify-content:space-around;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
}

.mps{
    text-decoration: none;
    color: white;
}

@media (max-width:481px)  { 
    .boxContaFoo{
        padding-block: 10px;
    }
}

.boxContaF{
    width: fit-content;
    height: fit-content;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    align-items:start;
    text-align: center;
    justify-content:start;
    vertical-align:middle;
    flex-direction: column;
    display: flex;
    padding-block: 30px;
}

.boxContaFoo{
    width: fit-content;
    height: fit-content;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    align-items:start;
    text-align: center;
    justify-content:start;
    vertical-align:middle;
    flex-direction: column;
    display: flex;
    margin-right: 35px;
    padding-block: 10px;
}

.boxContaFoo p{
    width: 100%;
    height: 30px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: white;
    align-items:center;
    text-align:left;
    justify-content:left;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
}

.boxFootTituloF{
    font-weight: bold;
    
    margin: none;
    padding: none;
    color: white;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.textDifContato{
    width: 300px;
    height: fit-content;
    
    margin-top: 10px;
    margin-bottom: 10px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: white;
    align-items:center;
    text-align:justify;
    justify-content:left;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
}

.inputEmail{
    width: 300px;
    height: 30px;
}

.boxBotao{
    width: 100%;
    height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    align-items:center;
    text-align:left;
    justify-content:left;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
}

.boxEmailMsg{
    width: 100%;
    height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    align-items:center;
    text-align:left;
    justify-content:left;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
}

.btEnviar{
    width: 100px;
    height: 30px;
    background-color: rgb(88, 90, 86);
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
    
    color: white;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
    border: 0.5px solid white;
    cursor: pointer;
}

.boxFootLinha{
    flex-direction: row;
}

.qkIconLocal{
    width: 15px;
    height: 15px;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    display: flex;
    margin-right: 25px;
}

.qkLinks{
    width: 80px;
    height: 50px;
    align-items: center;
    text-align: center;
    justify-content:space-around;
    vertical-align:middle;
    display: flex;
}

.footExtra{
    width: 100%;
    height: 50px;
    background-color: #f4f5f2;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    align-items: center;
    text-align: center;
    justify-content:space-evenly;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
}

.qkIcon{
    width: 15px;
    height: 15px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    display: flex;
}

.qkFace{
    cursor: pointer;
}

.qkInsta{
    cursor: pointer;
}