/* .logo{
    background-color: rgb(29,70,67);
    width: 170px;
    height: 70px;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
} */

.headMain{
    width: 127px;
    height: 87px;
    padding-inline: 5px;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    display: flex;
    text-decoration: none;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
    color: white;
}

.headMain a{
    width: 127px;
    height: 87px;
    padding-inline: 5px;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    display: flex;
    text-decoration: none;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
    color: white;
}

.headMain p{
    color: white;
}

.headMain:hover{
    width: 127px;
    height: 87px;
    padding-inline: 5px;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    display: flex;
    text-decoration: none;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
    background-color: rgb(26, 95, 91);
}

.entrar:hover{
    background-color: rgb(26, 95, 91);
}

.logoImage{
    margin-left: 20px;
    width: 150px;
    height: 65px;
    padding-block: 8px;
}

button{
    border: 0;
}

.head{
    background-color: rgb(29,70,67);
    border:1px solid rgb(29,70,67);
    width: 100%;
    height: fit-content;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
}

.navHead{
    background-color: rgb(29,70,67);
    border: rgb(29,70,67);
    width: 100%;
    height: 70px;
    align-items: center;
    text-align: center;
    justify-content:space-evenly;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
}

.botaoEntrar{
    margin-top: 10px;
    width: 100px;
    height: 45px;
    background-color: rgb(29,70,67);
    align-items: center;
    text-align: center;
    justify-content:center;
    vertical-align:middle;
    display: flex;
    text-decoration:none;
    border: none;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
    color: white;
    cursor: pointer;
}

.entrar{
    width: 70px;
    height: 25px;
    color: white;
    padding-inline: 15px;
    padding-block: 5px;
    border: 1px solid white;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:bold;
    color: white;
    text-decoration: none;
}

.BoxQkInfos{
    align-items: center;
    text-align: center;
    justify-content:center;
    display: flex;
    flex-wrap: wrap;
    background-color: rgb(29,70,67);
}

.BxQuickInfos{
    width: 100%;
    background-color: white;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    align-items: center;
    text-align: center;
    justify-content:space-around;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
}

@media (max-width:481px) {
    .navHead {
        display: none;
    } 
}

@media (min-width:1025px) {
    .BxQuickInfos p {
        font-size: 1.1vw;
    } 
}

@media (max-width:481px) {
    .qkBox{
        margin-block: 15px;
        width: 250px;
    } 
}

.qkBox{
    align-items: center;
    text-align: center;
    justify-content:left;
    vertical-align:middle;
    display: flex;
} 

.qkIcon{
    width: 15px;
    height: 15px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    display: flex;
    padding-right: 15px;
}

.qkData{
    width: 15px;
    height: 15px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align:middle;
    display: flex;
}

.qkTelef{
    height: 15px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    align-items: center;
    text-align: center;
    vertical-align:middle;
    display: flex;
    flex-direction: row;
}

.qkCreci{
    height: 15px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    align-items: center;
    text-align: center;
    vertical-align:middle;
    display: flex;
    flex-direction: row;
}

.qkEmail{
    height: 15px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    align-items: center;
    text-align: center;
    vertical-align:middle;
    display: flex;
    flex-direction: row;
}

.bg-menu{
    align-items:end;
    text-align:right;
    vertical-align:middle;
    display: flex;
    flex-direction: row;
    height: 30px;
    border: 0;
    background-color: rgb(227, 238, 237);
}

.mobile-menu-icon button{
    display: flex;
    flex-direction: row;
    background-color: rgb(29,70,67);
}

.alt{
    margin-right: 50px;
    border: 0;
    background-color: rgb(29,70,67);
}

.mobile-menu-icon{
    display: none;
    background-color: rgb(29,70,67);
}

.mobile-menu{
    display: none;
    background-color: rgb(29,70,67);
}

.logoHead{
    display: none;
    background-color: rgb(29,70,67);
}

.logoImageHead{
    display: none;
}

@media screen and (max-width:730px) {
    .logo{
        display: none;
    } 
    .BxQuickInfos{
        display: none;
    } 
    .headMain{
        display: none;
    } 
    .head{
        vertical-align: center;
        align-items:center;
        display: flex;
        justify-content:center;
        padding-block: 20px;
    } 
    .entrar{
        display: none;
    } 
    .mobile-menu-icon{
        display: block;
        cursor: pointer;
        margin-right: 10px;
        background-color: rgb(29,70,67);
    } 
    .mobile-menu{
        display: none;
        flex-direction: column;
        text-align: center;
        margin-right: 10px;
        justify-content:center;
    } 
    .open{
        display: block;

    }
    .mob-mn-itens p{
        justify-content:center;
        color:white;
        font-size: 15px;
    } 
    .logoHead{
        display: block;
        align-items: center;
        text-align: center;
        justify-content:center;
        display: flex;
        background-color: rgb(29,70,67);
        flex-wrap: wrap;
    }
    
    .logoImageHead{
        align-items: center;
        text-align: center;
        justify-content:center;
        display: flex;
        display: block;
        flex-wrap: wrap;
    }
}

.qkNetwork{
    height: 50px;
    align-items: center;
    text-align: center;
    justify-content:space-between;
    vertical-align:middle;
    display: flex;
    flex-direction: row;
}

.qkFace{
    cursor: pointer;
}

.qkInsta{
    cursor: pointer;
}

.qkZap{
    cursor: pointer;
}
