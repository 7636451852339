.documentos{
    width: 100%;
    height: fit-content;
    background-color:azure;
    align-items:center;
    text-align:justify;
    justify-content:start;
    vertical-align:top;
    flex-direction: column;
    display: flex;
}

.documentos h1{
    padding-top:30px;
    padding-bottom: 0px;
    align-items:center;
    text-align:center;
    justify-content:center;
    vertical-align:middle;
    flex-direction: row;
    display: flex;
    color: black;
}

.documentosCompraVenda{
    width: 85%;
    height: fit-content;
    margin-bottom: 5px;
    margin-top: 20px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    align-items:start;
    text-align:justify;
    justify-content:center;
    vertical-align:middle;
    flex-direction: column;
    display: flex;
}

.documentosLocatario{
    width: 85%;
    height: fit-content;
    margin-bottom: 50px;
    margin-top: 20px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    align-items:start;
    text-align:justify;
    justify-content:start;
    vertical-align:middle;
    flex-direction: column;
    display: flex;
}

.documentos li{
    padding-block:5px;
}

.documentos ul{
    list-style-position: inside;
}

.documentos p{
    padding-block:5px;
}

h2{
    padding-block:10px;
}

h3{
    padding-block:10px;
}